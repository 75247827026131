import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { ChevronRightIcon } from '../../../icons';

export interface IDropdownData {
  placeholder: string;
  selectedLabel?: string;
  isDisabled?: boolean;
  isValid?: boolean;
  isExpanded?: boolean;
  isActive?: boolean;
  isOpen?: boolean;
  name: string;
}

const dropdownItems = ['Item 1', 'Item 2', 'Item 3'];

const Dropdown: React.FC<{ data: IDropdownData }> = ({ data }) => {
  const { placeholder, selectedLabel, isActive, isValid, isOpen, name } = data;

  const dropdownEl = useRef<HTMLDivElement>(null);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const selectSpring = useSpring({
    height: isSelectOpen ? 'auto' : 0,
    overflow: isSelectOpen ? 'visible' : 'hidden',
  });

  useEffect(() => {
    isOpen && setIsSelectOpen(true);
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      const container = dropdownEl.current;

      if (
        container &&
        container !== e.target &&
        !container.contains(e.target)
      ) {
        setIsSelectOpen(false);
      }
    };

    document.addEventListener('click', (e) => handleClickOutside(e));

    return () => {
      document.removeEventListener('click', (e) => handleClickOutside(e));
    };
  }, []);

  const handleSelectTriggerClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    setIsSelectOpen((prev) => !prev);
  };

  return (
    <DropdownContainer>
      <DropdownName>{name}</DropdownName>
      <SelectContainer ref={dropdownEl}>
        <SelectButton
          aria-haspopup="listbox"
          onClick={handleSelectTriggerClick}
          isActive={isActive as boolean}
          isValid={isValid as boolean}
        >
          <span>{selectedLabel ? `${selectedLabel}` : placeholder}</span>
          {/*@ts-expect-error: SVG is autogenerated by Gatsby*/}
          <ChevronRightIcon />
        </SelectButton>
        <CustomSelect role="listbox" style={selectSpring} isOpen={isSelectOpen}>
          {dropdownItems?.map((item, index) => (
            <DropdownItem key={index} tabIndex={-1} active={index === 1}>
              {item}
            </DropdownItem>
          ))}
        </CustomSelect>
      </SelectContainer>
    </DropdownContainer>
  );
};

const DropdownContainer = styled.div`
  display: flex;
  margin-block-end: 40px;
  align-items: center;
`;
const DropdownName = styled.h4`
  inline-size: 166px;
`;

const SelectContainer = styled.div`
  position: relative;
  inset-block: 0;
  inset-inline-start: 0;
  inline-size: 220px;
  margin: 0;
  ${(props) => props.theme.mq.tablet} {
    inline-size: 280px;
  }
  select {
    display: none;
  }
`;

const SelectButton = styled.button<{ isActive: boolean; isValid: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  border: ${(props) =>
    !props.isValid
      ? `solid 1px #D4D6D9;`
      : `1px solid ${props.theme.colors.guardsmanRed};`}

  cursor: pointer;
  padding: 12px 20px;
  text-align: left;
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 14px;
  line-height: 1.86;
  color: ${(props) =>
    !props.isValid
      ? `${props.theme.colors.limedSpruce};`
      : `${props.theme.colors.guardsmanRed};`}
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
    padding-right: 20px;
  }
  svg {
    width: 7px;
    height: 10px;
    transform: rotate(90deg);
    ${(props) => props.isActive && `fill: ${props.theme.colors.guardsmanRed};`}
  }
  &:focus {
    outline: none;
    border-color: #8b99a6;
  }
`;

const CustomSelect = styled(animated.div)<{ isOpen?: boolean }>`
  display: flex;
  background: ${(props) => props.theme.colors.white};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  inline-size: 100%;
  z-index: 10;

  ${(props) =>
    props.isOpen &&
    css`
      border: solid 1px #d4d6d9;
      border-top: 0;
    `}

  a {
    color: ${(props) => props.theme.colors.limedSpruce};
    border: none;
    background: transparent;
    text-align: left;
    font-family: ${(props) => props.theme.fonts.gotham};
    font-size: 14px;
    line-height: 1.86;
    padding: 12px 20px;
    text-decoration: none;

    &:hover {
      background: ${(props) => props.theme.colors.porcelain};
      font-weight: 400;
      color: ${(props) => props.theme.colors.limedSpruce};
    }
  }
`;

const DropdownItem = styled.a<{ active: boolean }>`
  ${(props) =>
    props.active &&
    `background-color: ${props.theme.colors.porcelain}!important;`}
`;

export default Dropdown;
