import React from 'react';
import ColorsGrid, { IColors } from './ColorsGrid';

const colors: IColors[] = [
  {
    name: 'Slate',
    codes: {
      PMS: '7545 C',
      CMYK: '76/60/45/25',
      RGB: '67/83/99',
      HEX: '#435363',
    },
  },
  {
    name: 'Fire',
    codes: {
      PMS: '1665 C',
      CMYK: '0/84/100/0',
      RGB: '255/76/0',
      HEX: '#FF4C00',
    },
  },
  {
    name: 'Sea',
    codes: {
      PMS: '325 C',
      CMYK: '53/0/23/0',
      RGB: '100/204/201',
      HEX: '#64CCC9',
    },
  },
];

const PrimaryBrandColors: React.FC = () => <ColorsGrid colors={colors} />;

export default PrimaryBrandColors;
