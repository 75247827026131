import { LinkFragment } from '../../gatsby-graphql';

export const chunkArray = (array: any[], size: number): any[] => {
  const result: any[] = [];
  for (let i = 0; i < array.length; i += size) {
    const chunk = array.slice(i, i + size);
    result.push(chunk);
  }
  return result;
};

export const isBuildTime = typeof window === 'undefined';

export const _window: Window = isBuildTime ? ({} as Window) : window;

export const getLinkTo = (url: LinkFragment['url']): string => `${url?.path}`;
