import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { TwoColumnImagesRowFragment } from '../../../gatsby-graphql';

const TwoColumnImagesRow: React.FunctionComponent<TwoColumnImagesRowFragment> = ({
  firstColumnTitle,
  secondColumnTitle,
  firstColumnImage,
  secondColumnImage,
  firstColumnSubtitle,
  secondColumnSubtitle,
  firstColumnDescription,
  secondColumnDescription,
}) => {
  return (
    <Container>
      <Column>
        {(firstColumnTitle || secondColumnTitle) && (
          <h4>
            {firstColumnTitle?.trim()}
            <span>{firstColumnSubtitle && firstColumnSubtitle}</span>
          </h4>
        )}
        <ImageContainer>
          <GatsbyImage
            alt={firstColumnImage?.description as string}
            image={firstColumnImage?.gatsbyImageData}
          />
        </ImageContainer>

        {firstColumnDescription && (
          <Description>
            {renderRichText(
              firstColumnDescription as RenderRichTextData<ContentfulRichTextGatsbyReference>
            )}
          </Description>
        )}
      </Column>
      <Column>
        {(firstColumnTitle || secondColumnTitle) && (
          <h4>
            {secondColumnTitle?.trim()}
            <span>{secondColumnSubtitle && secondColumnSubtitle}</span>
          </h4>
        )}
        <ImageContainer>
          <GatsbyImage
            alt={secondColumnImage?.description as string}
            image={secondColumnImage?.gatsbyImageData}
          />
        </ImageContainer>
        {secondColumnDescription && (
          <Description>
            {renderRichText(
              secondColumnDescription as RenderRichTextData<ContentfulRichTextGatsbyReference>
            )}
          </Description>
        )}
      </Column>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
  flex-direction: column;
  ${(props) => props.theme.mq.desktop} {
    flex-direction: row;
    gap: 40px;
  }

  h4 {
    margin-block-end: 20px;
    margin-block-start: 0;
    block-size: 22px;
  }
  span {
    display: block;
  }
`;

const Column = styled.div`
  flex: 1;
  width: 100%;
  ${(props) => props.theme.mq.desktop} {
    width: auto;
  }
`;

const ImageContainer = styled.div`
  text-align: left;
  ${(props) => props.theme.mq.desktop} {
    padding-inline-end: 80px;
  }
  & > .gatsby-image-wrapper {
    margin-top: 0 !important;
  }
`;

const Description = styled.div`
  ul,
  ol {
    padding-inline-start: 12px;

    p {
      margin-block: 0.5em;
    }
  }

  * {
    font-size: 14px;
  }
`;

export default TwoColumnImagesRow;
