import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import { GenericPageQuery } from '../../gatsby-graphql';
import Masthead from '../components/Masthead/Masthead';
import PageComponents from '../components/PageComponents/PageComponents';

interface IGenericTemplateProps {
  data: GenericPageQuery;
  path: string;
}

const GenericTemplate: React.FC<IGenericTemplateProps> = ({
  data: { page },
  path,
}) => {
  return (
    <Layout footer={page?.footer} header={page?.header} path={path}>
      <Masthead
        mastheadHeader={page?.mastheadHeader}
        mastheadContent={page?.mastheadContent}
        mastheadDisclaimer={page?.mastheadDisclaimer}
        mastheadAssetDownload={page?.mastheadAssetDownload}
      />
      <PageComponents components={page?.components} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query GenericPage($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      ...PageGlobals
      name
    }
  }
`;

export default GenericTemplate;
