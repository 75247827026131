import React from 'react';
import Typography, { ITypographyProps } from './Typography';

const typographyData: ITypographyProps = {
  fontName: 'Inconsolata',
  footerText: 'Inconsolata is a Google font',
};

const MonospaceTypeface: React.FC = () => <Typography {...typographyData} />;

export default MonospaceTypeface;
