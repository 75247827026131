import React from 'react';
import styled, { useTheme } from 'styled-components';
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { TwoColumnContentFragment } from '../../../gatsby-graphql';

const TwoColumnContent: React.FunctionComponent<TwoColumnContentFragment> = ({
  firstColumn,
  firstColumnUnderlineColor,
  secondColumn,
  secondColumnUnderlineColor,
}) => {
  const { colors } = useTheme();
  const underlineColor = {
    green: colors.paradiso,
    red: colors.guardsmanRed,
    none: undefined,
  };
  const options: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <GatsbyImage
            image={node.data.target.gatsbyImageData}
            alt={node.data.target.description}
          />
        );
      },
    },
  };
  return (
    <Container>
      {firstColumn && (
        <Column color={underlineColor[firstColumnUnderlineColor as string]}>
          {renderRichText(
            firstColumn as RenderRichTextData<ContentfulRichTextGatsbyReference>,
            options
          )}
        </Column>
      )}
      {secondColumn && (
        <Column color={underlineColor[secondColumnUnderlineColor as string]}>
          {renderRichText(
            secondColumn as RenderRichTextData<ContentfulRichTextGatsbyReference>,
            options
          )}
        </Column>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 35px;
  ${(props) => props.theme.mq.tablet} {
    flex-direction: row;
  }
`;

const Column = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;

  p {
    margin-block: 0;

    & + .gatsby-image-wrapper {
      margin-block-start: 1em;
    }
  }

  ul {
    padding-inline-start: 25px;
    margin-block: 0;
  }

  h4 {
    padding-block-end: 0;
    color: transparent;
    margin-block-end: 31px;
    ${(props) => props.theme.mq.desktop} {
      padding: 0 0 12px 6px;
    }
    display: block;
    ${(props) => props.color && `border-bottom: 1px dashed ${props.color};`}
    font-family: ${(props) => props.theme.fonts.gotham};
    color: ${(props) => props.theme.colors.limedSpruce};
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.2em;
    position: relative;
    text-transform: uppercase;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      inline-size: 11px;
      block-size: 11px;
      border-radius: 100%;
      background-color: ${(props) => props.color};
      display: none;
      ${(props) => props.theme.mq.desktop} {
        display: block;
      }
    }
  }
  .gatsby-image-wrapper {
    inline-size: fit-content !important;
    margin-block-end: 20px;
    margin-block-start: -10px;
    ${(props) => props.theme.mq.tablet} {
      margin-block-end: 40px;
    }
  }
`;

export default TwoColumnContent;
