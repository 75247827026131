import React from 'react';
import styled from 'styled-components';

export interface ITypographyVariationProps {
  fontName: string;
}
export interface ITypographyProps {
  fontName: string;
  footerText: string;
}

const TypographyVariations: React.FC<ITypographyVariationProps> = ({
  fontName,
}) => {
  return fontName === 'Inconsolata' ? (
    <span>Regular</span>
  ) : (
    <span>
      Book, <i>Book Italic, </i>
      <b>
        Bold and <i>Bold Italic</i>
      </b>
    </span>
  );
};

const Typography: React.FC<ITypographyProps> = ({ fontName, footerText }) => (
  <TypographyContainer>
    <TypographyText font={fontName}>
      <em>{fontName}: </em>
      <TypographyVariations fontName={fontName} />
    </TypographyText>
    <Footer font={fontName}>{footerText}</Footer>
  </TypographyContainer>
);
const TypographyContainer = styled.div`
  padding-block-end: 10px;
`;

const TypographyText = styled.p<{ font: string }>`
  font-size: 20px;
  ${(props) =>
    props.font === 'GT Sectra' &&
    `font-family: ${props.theme.fonts.GTSectra};
     font:size: 18px;`}
  ${(props) =>
    props.font === 'Inconsolata' &&
    `font-family: ${props.theme.fonts.Inconsolata};
     font:size: 18px;`}
  span {
    ${(props) =>
      props.font === 'Gotham' &&
      `font-size: 14px;
       letter-spacing: 0.2em; 
       text-transform: uppercase;`}
  }
  em {
    font-style: normal;
    ${(props) => props.font === 'Gotham' && `font-weight:700; `}
  }
`;

const Footer = styled.p<{ font: string }>`
  font-size: 14px;
  ${(props) =>
    props.font === 'GT Sectra' &&
    `font-family: ${props.theme.fonts.GTSectra}; 
     line-height: 1.6;`}
  ${(props) =>
    props.font === 'Gotham' &&
    `font-family: ${props.theme.fonts.gotham}; 
     line-height: 2.35;`}
  ${(props) =>
    props.font === 'Inconsolata' &&
    `font-family: ${props.theme.fonts.Inconsolata}; 
     line-height: 1.6;`}
`;

export default Typography;
