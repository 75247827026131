import React from 'react';
import styled from 'styled-components';
import { GroupLinkFragment, LinkFragment } from '../../../gatsby-graphql';
import SiteLink from '../SiteLink/SiteLink';

interface IMobileHeaderGroupLinkProps extends GroupLinkFragment {
  drawerCloseLabel: string;
  forceDrawerClose: boolean;
}

const MobileHeaderGroupLink: React.FC<IMobileHeaderGroupLinkProps> = ({
  placeholder,
  links,
}) => {
  return (
    <>
      <h2>{placeholder}</h2>
      <div>
        {links?.map((link) => (
          <DrawerLink
            tabIndex={-1}
            key={link?.id}
            {...(link as LinkFragment)}
          />
        ))}
      </div>
    </>
  );
};

const DrawerLink = styled(SiteLink)`
  display: block;
  padding: 14px 0;
  color: ${(props) => props.theme.colors.limedSpruce};
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
`;

export default MobileHeaderGroupLink;
