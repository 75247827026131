import { DefaultTheme } from 'styled-components';
const breakpoints = {
  tablet: 768,
  desktop: 1024,
};
const theme: DefaultTheme = {
  colors: {
    white: '#FFFFFF',
    alto: '#D5D5D5',
    porcelain: '#f4f5f6',
    fiord: '#435363',
    limedSpruce: '#323f4a',
    ronchi: '#ebc959',
    paradiso: '#297a76',
    blackSqueeze: '#EBF8F8',
    grenadier: '#d64305',
    downy: '#64ccc9',
    guardsmanRed: '#C50000',
    success: '#29941A',
    slateLight: '#8B99A6',
    fire: '#FF4C00',
    fireDark: '#D93600',
    yellowMuted: '#F7D18F',
  },
  sizes: {
    containerMaxWidth: 1220,
    mobileHeaderHeight: 75,
    sideNavigation: {
      contentOffset: 'padding-left: 412px',
    },
  },
  fonts: {
    gotham: `'Gotham A', 'Gotham B', arial, sans-serif`,
    GTSectra: '"GT Sectra"',
    Inconsolata: '"Inconsolata"',
  },
  generics: {
    transitionTime: 'all 0.3s ease-in-out',
    boxShadow: '0 0 14px 1px rgba(0, 0, 0, 0.1)',
  },
  breakpoints,
  mq: {
    tablet: `@media (min-width: ${breakpoints.tablet}px)`,
    desktop: `@media (min-width: ${breakpoints.desktop}px)`,
  },
};
export default theme;
