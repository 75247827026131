import React from 'react';
import styled from 'styled-components';

import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import { Options } from '@contentful/rich-text-react-renderer';
import { GatsbyImage } from 'gatsby-plugin-image';
import { MastheadFragment } from '../../../gatsby-graphql';
import { BaseContainer } from '../BaseComponents';
import AssetDownload from '../AssetDownload/AssetDownload';

const richtextOptions: Options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <GatsbyImage
          image={node.data.target.gatsbyImageData}
          alt={node.data.target.description}
        />
      );
    },
  },
};

const Masthead: React.FC<MastheadFragment> = ({
  mastheadHeader,
  mastheadContent,
  mastheadDisclaimer,
  mastheadAssetDownload,
}) => (
  <Container>
    <ContentContainer>
      <h1>{mastheadHeader?.mastheadHeader}</h1>
      {mastheadContent && (
        <Description>
          {renderRichText(
            mastheadContent as RenderRichTextData<ContentfulRichTextGatsbyReference>,
            richtextOptions
          )}
        </Description>
      )}
      {mastheadDisclaimer && (
        <Disclaimer>{mastheadDisclaimer?.mastheadDisclaimer}</Disclaimer>
      )}
    </ContentContainer>
    <DownloadPlaceholder>
      {mastheadAssetDownload && (
        <AssetDownload
          header={mastheadAssetDownload.header}
          lastUpdatedLabel={mastheadAssetDownload.lastUpdatedLabel}
          assets={mastheadAssetDownload.assets}
        />
      )}
    </DownloadPlaceholder>
  </Container>
);

const Container = styled(BaseContainer)`
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  ${(props) => props.theme.mq.desktop} {
    padding-bottom: 100px;
    flex-direction: row;
  }
`;

const ContentContainer = styled.div`
  ${(props) => props.theme.mq.desktop} {
    padding-right: 103px;
    flex: 1;
  }
  h1 {
    margin: 0 0 20px;
    max-width: 590px;
  }
  p {
    white-space: pre-line;
  }
  p + p {
    margin-top: 20px;
  }
`;

const Description = styled.div`
  p {
    font-family: ${(props) => props.theme.fonts.GTSectra};
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 30px;
    letter-spacing: normal;
    color: ${(props) => props.theme.colors.limedSpruce};
    margin: 0;
    ${(props) => props.theme.mq.desktop} {
      font-size: 21px;
      line-height: 33px;
    }
  }

  .gatsby-image-wrapper {
    display: block;
    inline-size: auto;
    margin-block-start: 24px;
  }
`;

const Disclaimer = styled.p`
  font-family: ${(props) => props.theme.fonts.gotham};
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.fiord};
  margin: 20px 0 0;
`;

const DownloadPlaceholder = styled.div`
  width: 100%;
  ${(props) => props.theme.mq.desktop} {
    width: 373px;
    margin-top: 0;
  }
`;

export default Masthead;
